/* eslint-disable consistent-return */
/* eslint-disable indent */
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import _ from 'lodash';
import Router from 'next/router';
import Link from 'next/link';
import { Menu, Dropdown, Badge } from 'antd';
import DropdownButton from '../../assets/images/dropdownButton.png';
import CompanyImg from '../../assets/images/companyImg.png';
import Mobile_ShoppingCart_Img from '../../assets/images/mobile_shoppingcart_img.png';
import Mobile_Company_Img from '../../assets/images/mobile_company_img.png';
import DrawerImg from '../../assets/images/drawerbutton.png';
import MobileMainHeader from '../MobileMainHeader';
import * as AuthQueries from '../../views/auth/queries';
import * as DiagnosisReportQueries from '../../views/diagnosisReport/queries';
import * as CartQuery from '../../views/cart/queries';
import Logout from '../MyPageLogout';

import kidneyLogo from '../../assets/images/kidney_BI_Logo.png';

const Header = ({ isSticky, store, count }) => {
  const {
    data: {
      auth: { isLoggedIn }
    }
  } = useQuery(AuthQueries.AUTH);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const handleHome = () => {
    Router.push('/').then(() => {
      window.scrollTo(0, 0);
    });
  };

  const { data: allRecordResultData } = useQuery(DiagnosisReportQueries.ALL_RECORD_RESULT);
  const allRecordResult = _.get(allRecordResultData, 'allRecordResult');
  const allRecordResultExist = !_.isEmpty(allRecordResult);
  const handleOrderButton = () => {
    Router.push('/mealChoice').then(() => {
      window.scrollTo(0, 0);
    });
  };

  // # remote cart query
  const { data: userCartsData, refetch } = useQuery(CartQuery.USER_CARTS, {
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network'
  });
  const userCarts = _.get(userCartsData, 'userCarts.cartLines');
  const cartLineMount = _.size(userCarts);

  // # add cart
  // const handleCart = () => {
  //   Router.push({
  //     pathname: '/cart'
  //     // qeury: { productId }
  //   }).then(() => {
  //     window.scrollTo(0, 0);
  //   });
  // };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link href="/about">회사소개</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link href="/what-is-customized-diet">맞춤식단이란?</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link href="/eatmarket-about">맛저몰이란?</Link>
      </Menu.Item>
    </Menu>
  );

  const menus = (
    <Menu>
      <Menu.Item key="1">
        <Link href="/diagnosis"> 투석 맞춤식단</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link href="/diagnosis"> 비투석 맞춤식단</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <RenewwalHeader>
        {/* 모바일뷰 */}
        <MobileFirstRow>
          <DrawerButton onClick={showDrawer}>
            <img src={DrawerImg} alt="drawerimg"></img>
          </DrawerButton>
          <MobileCompanyImg onClick={handleHome}>
            <img src={kidneyLogo} alt="mobilecompanyimg" />
          </MobileCompanyImg>
          <MobileShoppingCart href="/cart">
            <MobileShoppingCartImg>
              <img src={Mobile_ShoppingCart_Img} alt="mobileshoppingcartimg" />
            </MobileShoppingCartImg>
            <HeaderBadge
              showZero
              count={cartLineMount}
              style={{
                backgroundColor: '#ff8a46',
                fontSize: '1.2rem',
                width: '2.4rem',
                height: '2.4rem',
                borderRadius: '1.2rem',
                lineheight: '2.4rem',
                paddingTop: '0.2rem'
              }}
            />
          </MobileShoppingCart>
        </MobileFirstRow>
        <MobileSecondRow>
          <ul>
            <Link href="/mealChoice">
              <li>
                <span>맞춤식단</span>
              </li>
            </Link>
            <Link href="/eatmarket">
              <li>
                <span>맛저몰</span>
              </li>
            </Link>
            <Link href="/consultation">
              <li>
                <span>영양코칭</span>
              </li>
            </Link>
            <Link href="/event">
              <li>
                <span>이벤트</span>
              </li>
            </Link>
          </ul>
        </MobileSecondRow>
        {/* 웹뷰 */}

        <FirstRow>
          <LogoAndLogin>
            <ComanyImg onClick={handleHome}>
              <img src={kidneyLogo} alt="companyimg" />
            </ComanyImg>
            <LoginUl>
              {isLoggedIn ? (
                <Heosu>
                  <ul>
                    <Logout isRenderBool={false} />
                    <Link href="/faq">
                      <li>
                        <span>자주묻는질문</span>
                      </li>
                    </Link>
                  </ul>
                </Heosu>
              ) : (
                <ul>
                  <Link href="/signup">
                    <li>
                      <YellowSign>
                        <span>회원가입</span>
                      </YellowSign>
                    </li>
                  </Link>
                  <Link href="/login">
                    <li>
                      <span>로그인</span>
                    </li>
                  </Link>
                  <Link href="/faq">
                    <li>
                      <span>자주묻는질문</span>
                    </li>
                  </Link>
                </ul>
              )}
            </LoginUl>
          </LogoAndLogin>
        </FirstRow>
        <SecondRow>
          <LeftUl>
            <ul>
              <Link href="/mealChoice">
                <li>
                  <span>맞춤식단</span>
                </li>
              </Link>
              <Link href="/eatmarket">
                <li>
                  <span>맛저몰</span>
                </li>
              </Link>
              <Link href="/consultation">
                <li>
                  <span>영양코칭</span>
                </li>
              </Link>
              <Link href="/event">
                <li>
                  <span>이벤트</span>
                </li>
              </Link>
              <li>
                <Dropdown overlay={menu}>
                  <Introduction>
                    <span>맛있저염 소개</span>
                    <DropdownImg>
                      <img src={DropdownButton} alt="dropdownbutton" />
                    </DropdownImg>
                  </Introduction>
                </Dropdown>
              </li>
            </ul>
          </LeftUl>
          <RightUl>
            {isLoggedIn ? (
              <ul>
                <Link href="/my-page">
                  <li>
                    <span>마이페이지</span>
                  </li>
                </Link>
                <Link href="/cart">
                  <li>
                    <ShoppingCart href="/cart">
                      <HeaderBadge
                        showZero
                        count={cartLineMount}
                        offset={[20, 8]}
                        style={{
                          backgroundColor: '#ff8a46',
                          fontSize: '1.2rem',
                          width: '2.4rem',
                          height: '2.4rem',
                          borderRadius: '1.2rem',
                          lineheight: '2.4rem',
                          paddingTop: '0.3rem'
                        }}
                      >
                        장바구니
                      </HeaderBadge>
                    </ShoppingCart>
                  </li>
                </Link>
              </ul>
            ) : (
              <ul>
                <Link href="/login">
                  <li>
                    <ShoppingCart href="/cart">
                      <HeaderBadge
                        showZero
                        count={cartLineMount}
                        offset={[20, 8]}
                        style={{
                          backgroundColor: '#ff8a46',
                          fontSize: '1.2rem',
                          width: '2.4rem',
                          height: '2.4rem',
                          borderRadius: '1.2rem',
                          lineheight: '2.4rem',
                          paddingTop: '0.3rem'
                        }}
                      >
                        장바구니
                      </HeaderBadge>
                    </ShoppingCart>
                  </li>
                </Link>
              </ul>
            )}
          </RightUl>
        </SecondRow>
      </RenewwalHeader>
      <MobileMainHeader count={cartLineMount} handleOrderButton={handleOrderButton} isLoggedIn={isLoggedIn} onClose={onClose} visible={visible} placement="left" />
    </>
  );
};
const RenewwalHeader = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 112rem;
  color: #222;

  ${props => props.theme.device.sm} {
    width: 100%;
    margin: 0 auto;
  }
`;
const FirstRow = styled.div`
  height: 9.6rem;
  padding-top: 1.8rem;
  width: 100%;
  ${props => props.theme.device.sm} {
    display: none;
  }
`;
const SecondRow = styled.div`
  height: 5.6rem;
  font-family: TmoneyRoundWindRegular;
  display: flex;
  padding: 0 1.6rem;
  justify-content: space-between;
  width: 100%;
  line-height: 1.5;
  ${props => props.theme.device.sm} {
    display: none;
  }
`;
const LeftUl = styled.div`
  height: 100%;
  padding-top: 1.8rem;
  span {
    font-size: 2rem;
  }
  ul {
    display: flex;
    list-style-type: none;
    height: 2rem;
    padding-left: 0;
  }
  li {
    text-align: left;
    border-left: 1px solid #cecece;
    padding: 0px 3.2rem;
    cursor: pointer;
    line-height: 2rem;

    a {
      color: #222;
    }
  }
  li:first-child {
    border-left: none;
  }
`;
const RightUl = styled.div`
  height: 100%;
  padding-top: 1.8rem;
  font-size: 2rem;
  ul {
    display: flex;
    list-style-type: none;
    height: 2rem;
    padding-left: 0;
  }
  li {
    height: 2rem;
    border-left: 1px solid #cecece;
    padding: 0px 2.4rem;
    cursor: pointer;
    line-height: 2rem;
  }
  li:first-child {
    border-left: none;
  }
`;
const Introduction = styled.div`
  display: flex;
`;
const DropdownImg = styled.div`
  img {
    width: 1.6rem;
    height: 0.7rem;
    margin-left: 1.4rem;
  }
`;
const ShoppingCart = styled.a`
  display: flex;
  height: 2rem;
  line-height: 2rem;
  color: #222;
  :hover {
    color: #222;
  }
  span {
    font-size: 2rem;
  }
`;
const ShoppingCartImg = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background-color: blue;
  margin-left: 1.2rem;
`;

const LogoAndLogin = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ComanyImg = styled.div`
  width: 18rem;
  img {
    width: 100%;
    cursor: pointer;
  }
`;

const LoginUl = styled.div`
  height: 2.4rem;
  font-family: Spoqa Han Sans;
  margin-left: 15rem;
  margin-right: 2rem;
  font-size: 1.6rem;
  ul {
    display: flex;
    list-style-type: none;
    height: 2rem;
    padding-left: 0;
  }
  li {
    height: 1.6rem;
    border-left: 1px solid #cecece;
    padding: 0px 2.4rem;
    cursor: pointer;
    line-height: 2rem;
  }
  li:first-child {
    border-left: none;
  }
`;
const YellowSign = styled.div`
  color: #ff7e1f;
`;

// 모바일 ver.
const MobileFirstRow = styled.div`
  display: none;
  ${props => props.theme.device.sm} {
    display: inline-block;
    height: 5.6rem;
    width: 100%;
    padding: 1.2rem 0 1.11rem;
    display: flex;
    justify-content: space-around;
  }
`;

const MobileSecondRow = styled.div`
  display: none;
  ${props => props.theme.device.sm} {
    display: inline-block;
    height: 4.8rem;
    width: 100%;
    padding: 1.7rem 0 1.5rem;
    font-size: 1.6rem;
    font-family: TmoneyRoundWindRegular;

    ul {
      display: flex;
      list-style-type: none;
      padding-left: 0px;
      justify-content: center;
      /* flex-wrap: wrap; */
    }
    li {
      border-left: 1px solid #cecece;
      padding: 0px 1.6rem;
      cursor: pointer;
      height: 1.6rem;
      line-height: 1.6rem;
    }
    li:first-child {
      border-left: none;
    }
  }
  ${props => props.theme.device.mi} {
    font-size: 1.5rem;
  }
`;
const DrawerButton = styled.div`
  margin-top: 0.8rem;
  width: 5.5rem;
  cursor: pointer;
  img {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
const MobileCompanyImg = styled.div`
  cursor: pointer;
  width: 12rem;
  margin-top: 0.4rem;
  img {
    width: 100%;
  }
`;
const MobileShoppingCartImg = styled.div`
  cursor: pointer;
  margin-right: 1.2rem;

  img {
    width: 2.7rem;
    height: 2.4rem;
  }
`;

const MobileShoppingCart = styled.a`
  cursor: pointer;
  display: flex;
  margin-top: 0.4rem;
`;

const MobileShoppingCartAlarm = styled.div`
  background-color: #ff7f32;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 0.4rem;
  border-radius: 1.2rem;
`;

const Heosu = styled.div`
  margin-left: 9rem;
`;

const HeaderBadge = styled(Badge)`
  ${props => props.theme.device.sm} {
    font-size: 1.4rem;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
  }
`;
export default Header;
