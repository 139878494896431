import React from 'react';

import styled from 'styled-components';
import { withRouter } from 'next/router';
import { compose, graphql } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import Link from 'next/link';

import * as AuthQueries from '../../views/auth/queries';
import * as DiagnosisReportQueries from '../../views/diagnosisReport/queries';

import HomeSVG from '../../assets/svg/home';
import CloseSVG from '../../assets/svg/close';

const HeaderMobileMenu = ({
  authData: {
    auth: { isLoggedIn }
  },
  isMenuOpen,
  onClose
}) => {
  const { data: allRecordResultData } = useQuery(DiagnosisReportQueries.ALL_RECORD_RESULT);
  const allRecordResult = _.get(allRecordResultData, 'allRecordResult');
  const allRecordResultExist = !_.isEmpty(allRecordResult);
  return (
    <Wrapper isMenuOpen={isMenuOpen}>
      {isMenuOpen ? (
        <>
          <Header>
            <HeaderContainer>
              <Home>
                <Link href="/">
                  <a>
                    <HomeSVG />
                  </a>
                </Link>
              </Home>

              <Close>
                <button type="button" onClick={onClose}>
                  <CloseSVG />
                </button>
              </Close>
            </HeaderContainer>
          </Header>

          <Content>
            <Menu>
              <OrderButtonDiv>
                <OrderUl>
                  <li>
                    맞춤식단
                    <ol>
                      <li>
                        <Link href={allRecordResultExist ? '/diagnosis-report' : '/diagnosis'}>
                          <a>맞춤식단</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/shops">
                          <a> 도시락</a>
                        </Link>
                      </li>
                    </ol>
                  </li>
                </OrderUl>
              </OrderButtonDiv>
              <OrderButtonDiv>
                <OrderUl>
                  <li>
                    <Link href="/reviews">
                      <a style={{ color: '#323232' }}>고객후기</a>
                    </Link>
                  </li>
                </OrderUl>
              </OrderButtonDiv>

              <OrderButtonDiv>
                <OrderUl>
                  <li>
                    <Link href="/menus">
                      <a style={{ color: '#323232' }}>이달의 맞춤식단표</a>
                    </Link>
                  </li>
                </OrderUl>
              </OrderButtonDiv>

              <OrderButtonDiv>
                <OrderUl>
                  <li>
                    <Link href="/consultation">
                      <a style={{ color: '#323232' }}>영양코칭</a>
                    </Link>
                  </li>
                </OrderUl>
              </OrderButtonDiv>

              <OrderButtonDiv>
                <OrderUl>
                  <li>
                    맛있저염 소개
                    <ol>
                      <li>
                        <Link href="/about">
                          <a>회사 소개</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/what-is-customized-diet">
                          <a>맞춤식단이란?</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/consultation">
                          <a>영양코칭이란?</a>
                        </Link>
                      </li>
                    </ol>
                  </li>
                </OrderUl>
              </OrderButtonDiv>
            </Menu>

            <Util>
              {isLoggedIn ? (
                <>
                  <Link href="/my-page">
                    <a>
                      <Me onClick={onClose}>마이페이지</Me>
                    </a>
                  </Link>
                </>
              ) : (
                <>
                  <Link href="/login">
                    <a>
                      <Login>로그인</Login>
                    </a>
                  </Link>
                  <Link href="/signup">
                    <a>
                      <Signup>회원가입</Signup>
                    </a>
                  </Link>
                </>
              )}
            </Util>
          </Content>
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => (props.isMenuOpen ? '100%' : '0%')};
  width: 100vw;
  background-color: white;
  z-index: 90;
`;

const Header = styled.div`
  height: 5.2rem;
  border-bottom: 1px solid #bdbdbd;
  box-sizing: content-box;
`;

const HeaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 2rem;
  height: 10rem;
`;

const Content = styled.div``;

const Home = styled.div``;

const Close = styled.div`
  button {
    padding: 1rem;
    margin-right: -1rem;
  }
`;

const Menu = styled.div``;

const Util = styled.div`
  margin: 2rem 2rem 0rem 2rem;
`;

const Me = styled.button`
  margin-bottom: 1rem;
  width: 100%;
  height: 4.8rem;
  background-color: white;
  font-size: ${props => props.theme.fontSize.mobile.button};
  font-weight: 700;
  color: #2f6ecd;
  padding: unset;
  border: 1px solid #2f6ecd;
  cursor: pointer;
`;

const Login = styled.button`
  width: 100%;
  height: 4.8rem;
  font-size: ${props => props.theme.fontSize.mobile.button};
  font-weight: 700;
  color: white;
  background-color: #f59005;
  padding: unset;
  border: unset;
  cursor: pointer;
  margin-bottom: 1.6rem;
`;

const Signup = styled.button`
  width: 100%;
  height: 4.8rem;
  background-color: white;
  font-size: ${props => props.theme.fontSize.mobile.button};
  font-weight: 700;
  color: #2f6ecd;
  padding: unset;
  border: 1px solid #2f6ecd;
  cursor: pointer;
`;

const OrderButtonDiv = styled.div``;
const OrderUl = styled.ul`
  border-bottom: 1px solid #bdbdbd;
  list-style: none;
  line-height: 3.5rem;
  font-size: ${props => props.theme.fontSize.mobile.button};
  font-weight: 700;
  color: ${props => (props.cta ? 'white' : '#323232')};
  padding-left: 2rem;
  margin-bottom: 0;
  li {
    padding: 1.4rem 0;
    ol {
      padding-left: 1.5rem;
      li {
        list-style: none;
        height: 100%;
        font-size: ${props => props.theme.fontSize.mobile.button};
        font-weight: 700;
        color: ${props => (props.cta ? 'white' : '#323232')};
        padding: 0;
      }
    }
  }
  a {
    display: block;
    color: #808080;
  }
  a:hover {
    color: #2f6ecd;
  }
`;

export default withRouter(
  compose(
    graphql(AuthQueries.AUTH, {
      props: ({ data }) => ({
        authData: data
      })
    })
  )(HeaderMobileMenu)
);
