import styled from 'styled-components';
import { Icon, Badge, Drawer, Tag } from 'antd';
import HomeSVG from '../../assets/svg/home';

const MobileMainHeader = ({ count, handleOrderButton, isLoggedIn, onClose, visible, placement }) => {
  return (
    <>
      <MobileDrawer>
        <Drawer placement={placement} closable={false} onClose={onClose} visible={visible}>
          <CloseButton
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #bdbdbd'
            }}
          >
            <MobileLinkTag href="/" bold>
              <HomeSVG />
            </MobileLinkTag>
            <Icon type="close" onClick={onClose} style={{ fontSize: '3rem', marginRight: '1rem' }} />
          </CloseButton>
          <MobileMenu style={{ listStyle: 'none' }}>
            <MobileMenuContainer>
              <label>잇마플</label>
              <MobileSubMenu>
                <MobileLinkTag href="/about">회사소개</MobileLinkTag>
                <MobileLinkTag href="/what-is-customized-diet">맞춤식단이란?</MobileLinkTag>
                <MobileLinkTag href="/eatmarket-about">맛저몰이란?</MobileLinkTag>
              </MobileSubMenu>
            </MobileMenuContainer>
            <MobileMenuContainer>
              <MobileLinkTag href="/eatmarket" bold>
                맛저몰
              </MobileLinkTag>
            </MobileMenuContainer>
            <MobileMenuContainer>
              <label>맞춤식단</label>
              <MobileSubMenu>
                <MobileLinkTag onClick={handleOrderButton}>주문하기</MobileLinkTag>
                <MobileLinkTag href="/menus">이달의 식단</MobileLinkTag>
                <MobileLinkTag href="/reviews">고객후기</MobileLinkTag>
              </MobileSubMenu>
            </MobileMenuContainer>
            <MobileMenuContainer>
              <MobileLinkTag href="/consultation" bold>
                영양코칭
              </MobileLinkTag>
            </MobileMenuContainer>
            <MobileMenuContainer>
              <MobileLinkTag href="/faq" bold>
                자주 묻는 질문
              </MobileLinkTag>
            </MobileMenuContainer>
            {isLoggedIn ? (
              <>
                <MobileMenuContainer>
                  <MobileLinkTag href="/my-page" bold>
                    마이페이지
                  </MobileLinkTag>
                </MobileMenuContainer>
                <MobileMenuContainer>
                  <MobileLinkTag href="/cart" bold>
                    <BadgeBox count={count} showZero offset={[15, 6]} style={{ backgroundColor: '#ff8a46', fontSize: '1.2rem' }}>
                      장바구니
                    </BadgeBox>
                  </MobileLinkTag>
                </MobileMenuContainer>
              </>
            ) : (
              <>
                <MobileMenuContainer>
                  <MobileLinkTag href="/signup" bold>
                    회원가입
                  </MobileLinkTag>
                </MobileMenuContainer>
                <MobileMenuContainer>
                  <MobileLinkTag href="/login" bold>
                    로그인
                  </MobileLinkTag>
                </MobileMenuContainer>
              </>
            )}
          </MobileMenu>
        </Drawer>
      </MobileDrawer>
    </>
  );
};

const MobileDrawer = styled.div`
  display: block;
  .ant-drawer-body {
    padding: 10px;
  }
  ${props => props.theme.device.md} {
    display: none;
  }
`;
const Home = styled.div``;
const Hamburger = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  height: 5.2rem;
  button {
    padding: 1rem;
    margin-right: -1rem;
  }
`;
const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const MobileMenu = styled.div``;
const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #bdbdbd;
  font-size: 1.8rem;
  padding: 1rem;
  label {
    color: #323232;
    font-weight: 700;
    font-size: 1.4rem;
  }
`;
const MobileLinkTag = styled.a`
  color: #323232;
  margin: 0.5rem 0;
  font-size: 1.4rem;
  font-weight: ${props => (props.bold ? '700' : 'normal')};
`;
const BadgeBox = styled(Badge)`
  font-size: 1.4rem;
  ${props => props.theme.device.md} {
    font-size: 1.6rem;
  }
`;
const MobileSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
`;
export default MobileMainHeader;
