/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Icon } from 'antd';
import _ from 'lodash';
import Router, { withRouter } from 'next/router';
import { compose, graphql } from 'react-apollo';

import Link from 'next/link';

import HeaderMobileMenu from '../HeaderMobileMenu';

import DirectionImg from '../../assets/images/direction1.png';
import HamburgerSVG from '../../assets/svg/hamburger';
import LogoSVG from '../../assets/svg/logo';

import * as AuthQueries from '../../views/auth/queries';
import * as DiagnosisReportQueries from '../../views/diagnosisReport/queries';

const Header = ({ isSticky, title, beforeMoveToPrevPage }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const client = useApolloClient();

  useEffect(() => {
    async function fetchingData() {
      const {
        data: { auth }
      } = await client.query({ query: AuthQueries.AUTH });
      setLoggedIn(auth.isLoggedIn);
    }
    fetchingData();
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHoverMenuOpen, setIsHoverMenuOpen] = useState(false);
  const [isIntroductionMenuOpen, setIntroductionMenuOpen] = useState(false);
  const { data: allRecordResultData } = useQuery(DiagnosisReportQueries.ALL_RECORD_RESULT);
  const allRecordResult = _.get(allRecordResultData, 'allRecordResult');
  const allRecordResultExist = !_.isEmpty(allRecordResult);
  const handleOrderButton = () => {
    Router.push(allRecordResultExist ? '/diagnosis-report' : '/diagnosis').then(() => {
      window.scrollTo(0, 0);
    });
  };
  const handleIntroButton = () => {
    Router.push('/about').then(() => {
      window.scrollTo(0, 0);
    });
  };
  const handleMenuClick = () => setIsMenuOpen(!isMenuOpen);

  const handleEnter = () => {
    setIsHoverMenuOpen(true);
  };

  const handleIntroEnter = () => {
    setIntroductionMenuOpen(true);
  };

  const handleLeave = () => {
    setIsHoverMenuOpen(false);
  };

  const handleIntroLeave = () => {
    setIntroductionMenuOpen(false);
  };

  const handlePrevBtnClick = async () => {
    if (beforeMoveToPrevPage) await beforeMoveToPrevPage();
    Router.back();
  };

  return (
    <>
      <Wrapper isSticky={isSticky} style={{ backgroundColor: 'white' }}>
        <Container>
          <Left>
            <ImageWrapper onClick={handlePrevBtnClick}>
              <img src={DirectionImg} alt="뒤로가기 이미지" />
            </ImageWrapper>
          </Left>
          <Logo>
            <Link href="/">
              <a>
                <LogoSVG />
              </a>
            </Link>
          </Logo>

          {title && <HeaderTitle>{title}</HeaderTitle>}

          <Nav>
            <NavContainer>
              <OrderButtonContainer>
                <OrderButton
                  onClick={handleOrderButton}
                  style={
                    isHoverMenuOpen
                      ? {
                        fontWeight: '700',
                        color: '#2f6ecd',
                        borderBottom: '5px solid #2f6ecd'
                      }
                      : null
                  }
                  onMouseEnter={handleEnter}
                  onMouseLeave={handleLeave}
                >
                  상품주문
                </OrderButton>
                {isHoverMenuOpen ? (
                  <>
                    <HoverMenu onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
                      <Link href={allRecordResultExist ? '/diagnosis-report' : '/diagnosis'}>
                        <a>맞춤식단</a>
                      </Link>
                      <Link href="/shops">
                        <a>도시락</a>
                      </Link>
                    </HoverMenu>
                  </>
                ) : null}
              </OrderButtonContainer>
              {/* 영양컨설팅 버튼 */}
              <ConsultingContainer>
                <Link href="/consultation">
                  <ConsultingButton>영양코칭</ConsultingButton>
                </Link>
              </ConsultingContainer>
              {/* 맛있저염소개 버튼 */}
              <MicrosaltsButtonContainer>
                <MicrosaltsButton
                  onClick={handleIntroButton}
                  style={
                    isIntroductionMenuOpen
                      ? {
                        fontWeight: '700',
                        color: '#2f6ecd',
                        borderBottom: '5px solid #2f6ecd'
                      }
                      : null
                  }
                  onMouseEnter={handleIntroEnter}
                  onMouseLeave={handleIntroLeave}
                >
                  맛있저염소개
                </MicrosaltsButton>
                {isIntroductionMenuOpen ? (
                  <>
                    <MicrosaltsHoverMenu
                      onMouseEnter={handleIntroEnter}
                      onMouseLeave={handleIntroLeave}
                    >
                      <Link href="/about">
                        <a>회사소개</a>
                      </Link>
                      <Link href="/what-is-customized-diet">
                        <a>맞춤식단이란?</a>
                      </Link>
                      <Link href="/consultation">
                        <a>영양코칭이란?</a>
                      </Link>
                    </MicrosaltsHoverMenu>
                  </>
                ) : null}
              </MicrosaltsButtonContainer>
              <ul>
                {isLoggedIn ? (
                  <>
                    <Me>
                      <Link href="/my-page">
                        <a>
                          {/* <MeSVG /> */}
                          <Icon type="user" style={{ fontSize: '2.2rem', color: '#2f6ecd' }} />
                          <span>마이페이지</span>
                        </a>
                      </Link>
                    </Me>
                  </>
                ) : (
                  <>
                    <li>
                      <Link href="/signup">
                        <a>회원가입</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/login">
                        <a>로그인</a>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </NavContainer>
          </Nav>

          <Hamburger onClick={handleMenuClick}>
            <button type="button">
              <HamburgerSVG />
            </button>
          </Hamburger>
        </Container>
      </Wrapper>
      <HeaderMobileMenu isMenuOpen={isMenuOpen} onClose={handleMenuClick} />
    </>
  );
};

const Wrapper = styled.div`
  /* ${props => {
    if (props.isSticky) {
      return `
        position: fixed;
        top: 0;
        width: 100%;
    `;
    }
  }} */
  z-index: 10;
  height: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  ${props => props.theme.device.md} {
    height: 7.2rem;
  }
`;

const Container = styled.div`
  margin: 0rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.device.md} {
    margin: 0 auto;
    max-width: 1200px;
    justify-content: space-between;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${props => props.theme.device.md} {
    display: none;
  }
`;
const Logo = styled.div`
  display: none;
  a {
    svg {
      width: 68px;
    }
  }
  ${props => props.theme.device.md} {
    display: block;
    a {
      svg {
        width: 98px;
      }
    }
  }
`;
const ImageWrapper = styled.div`
  img {
    width: 2.5rem;
    height: 2.5rem;

    ${props => props.theme.device.md} {
      width: 98px;
    }
  }

  ${props => props.theme.device.md} {
    margin-right: 6.8rem;
  }
`;

const HeaderTitle = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  color: #000000;
  font-weight: 700;
  ${props => props.theme.device.md} {
    display: none;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
// # 상품주문 버튼
const OrderButtonContainer = styled.div`
  height: 7.2rem;
`;

const OrderButton = styled.a`
  text-align: center;
  font-size: 1.6rem;
  line-height: 7.2rem;
  font-weight: 700;
  padding: 0;
  color: ${props => (props.active ? '#2F6ECD' : '#313131')};
  height: inherit;
  display: block;
  transition: unset;
  width: 13rem;
  &:hover {
    font-weight: 700;
    color: #2f6ecd;
    border-bottom: 5px solid #2f6ecd;
  }
`;

const HoverMenu = styled.div`
  border: 1px solid #e0e0e0;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  a {
    padding: 1rem 0;
    font-size: 1.6rem;
    color: inherit;
    font-weight: bold;
  }
  a:hover {
    color: #2f6ecd;
  }
`;

const Hamburger = styled.div`
  button {
    padding: 1rem;
    margin-right: -1rem;
  }

  ${props => props.theme.device.md} {
    display: none;
  }
`;

const Nav = styled.nav`
  display: none;

  ${props => props.theme.device.md} {
    height: inherit;
    white-space: nowrap;
    display: block;

    ul {
      display: flex;
      flex-direction: row;
      align-content: space-around;
      list-style: none;
      margin: 0;
      padding: 0;
      height: inherit;

      li {
        height: 7.2rem;

        a {
          margin: 0 2.2rem;
          font-size: 1.6rem;
          line-height: 7.2rem;
          font-weight: ${props => (props.active ? '700' : '400')};
          font-weight: 700;
          /* padding: 0 1rem;
          margin: 0 1rem; */
          color: ${props => (props.active ? '#2F6ECD' : '#313131')};
          height: inherit;
          display: block;
          transition: unset;
        }
        &:hover {
          font-weight: 700;
          border-bottom: 5px solid #2f6ecd;
          a {
            color: #2f6ecd;
          }
        }
      }
      li:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Me = styled.li`
  a {
    svg {
      vertical-align: middle;
      margin-right: 1.3rem;
    }

    span {
      vertical-align: middle;
      color: #2f6ecd;
      font-size: ${props => props.theme.fontSize.desktop.buttonTwo};
      font-weight: bold;
    }
  }
`;

// 영양컨설팅 버튼
const ConsultingContainer = styled.div`
  height: 7.2rem;
`;
const ConsultingButton = styled.a`
  display: block;
  text-align: center;
  cursor: pointer;
  width: 13rem;
  height: 7.2rem;
  font-size: 1.6rem;
  line-height: 7.2rem;
  font-weight: 700;
  padding: 0;
  color: ${props => (props.active ? '#2F6ECD' : '#313131')};
  height: inherit;
  transition: unset;
  a {
    color: inherit;
  }
  &:hover {
    font-weight: 700;
    color: #2f6ecd;
    border-bottom: 5px solid #2f6ecd;
  }
`;

// 맛있저염소개 버튼
const MicrosaltsButtonContainer = styled.div`
  height: 7.2rem;
`;
const MicrosaltsButton = styled.a`
  text-align: center;
  font-size: 1.6rem;
  line-height: 7.2rem;
  font-weight: 700;
  padding: 0;
  color: ${props => (props.active ? '#2F6ECD' : '#313131')};
  height: inherit;
  display: block;
  transition: unset;
  width: 14rem;
  &:hover {
    font-weight: 700;
    color: #2f6ecd;
    border-bottom: 5px solid #2f6ecd;
  }
`;

const MicrosaltsHoverMenu = styled.div`
  border: 1px solid #e0e0e0;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  a {
    padding: 1rem 0;
    font-size: 1.6rem;
    color: inherit;
    font-weight: bold;
  }
  a:hover {
    color: #2f6ecd;
  }
`;

export default withRouter(
  compose(
    graphql(AuthQueries.AUTH, {
      props: ({ data }) => ({
        authData: data
      })
    })
  )(Header)
);
